@tailwind base;
@tailwind components;
@tailwind utilities;

.typewriter-container {
    position: relative;
    height: 150px; /* Adjust the height to match the height of your text */
  }
  
  .typewriter-text {
    position: relative;
    top: 0;
    left: 0; /* Adjust this value to move the text to the right */
    width: 100%; /* Ensure the width is fixed */
    line-height: 1.3; /* Adjust this value to increase the space between lines */

  }
  .normalcontainer {
    position: relative;
    padding: 2rem;
  }

  @media (min-width: 1000px) {
    .normalcontainer {
      max-width: 1000px;
      margin: auto;
    }
  }


  
  .typewriter-container .Typewriter__wrapper {
    display: inline; /* Ensure the wrapper is inline so that the cursor is at the start */
  }
  
  .typewriter-container .Typewriter__cursor {
    position: relative;
    top: 0;
    left: 0;
    display: inline-block; /* Ensure the cursor is inline-block to follow the text */
  }
  
  .mission-title {
    font-size: 4rem; /* Adjust size as needed */
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .mission-content {
    font-size: 1rem;
    line-height: 1.5;
    text-align: justify;
    white-space: pre-wrap; /* Preserves line breaks and white space */
  }
  

  html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    position: relative;
}
  
body::before {
    content: '';
    position: fixed;
    top: -40%; /* Adjusted for smaller screens */
    left: -10%; /* Adjusted for smaller screens */
    bottom: -20%; /* Adjusted for smaller screens */
    right: -5%; /* Adjusted for smaller screens */
    width: 150%; /* Adjusted for smaller screens */
    height: 150%; /* Adjusted for smaller screens */
    @media (min-width: 768px) {
        top: -30%;
        left: -60%;
        bottom: -30%;
        right: -10%;
        width: 170%;
        height: 180%;
    }
    z-index: -1; /* Ensure the background is behind other content */
    background: radial-gradient(circle at 20% -25%, rgba(255, 0, 0, 1) 0%, transparent 30%),
                radial-gradient(circle at 40% -30%, rgb(255, 208, 0,1) 10%, transparent 40%),
                radial-gradient(circle at 40% -30%, rgb(255, 208, 0,1) 10%, transparent 30%),
                radial-gradient(circle at 60% -30%, rgb(83, 248, 0) 0%, transparent 40%),
                radial-gradient(circle at 110% -20%, rgb(21, 0, 255,1) 0%, transparent 45%),
                radial-gradient(circle at 70% -20%, rgb(0, 225, 255,1) 0%, transparent 35%),
                radial-gradient(circle at 70% -20%, rgb(0, 225, 255,1) 0%, transparent 35%),
                radial-gradient(circle at 70% -20%, rgb(0, 225, 255,1) 0%, transparent 35%),
                radial-gradient(circle at 70% -20%, rgb(0, 225, 255,1) 10%, transparent 35%),
                radial-gradient(circle at 10% 10%, rgba(0, 0, 0, 1) 0%, transparent 100%),
                radial-gradient(circle at 10% 10%, rgba(0, 0, 0, 1) 0%, transparent 150%),
                radial-gradient(circle at 50% 100%, rgba(0, 0, 0, 1) 0%, transparent 220%);
    background-color: rgb(0, 0, 0); /* Set the base background color */
    background-size: cover;
    background-attachment: fixed; /* Ensure the background stays fixed */
    filter: blur(0px); /* Apply blur to only the background */
}
/* Mobile styles */
@media (max-width: 768px) {
  body::before {
    top: -20%;
    left: -40%;
    bottom: -30%;
    right: -10%;
    width: 170%;
    height: 180%;


    background: radial-gradient(circle at 20% -15%, rgba(255, 0, 0, 0.5) 20%, transparent 30%),
                radial-gradient(circle at 50% 0%, rgb(255, 208, 0,1) 5%, transparent 20%),
                radial-gradient(circle at 60% -10%, rgb(83, 248, 0) 0%, transparent 30%),
                radial-gradient(circle at 50% 100%, rgba(0, 0, 0, 1) 40%, transparent 220%);
    background-color: rgb(0, 0, 0); /* Set the base background color */
  }
}

  
.content {
    position: relative;
    z-index: 1; /* Ensure content is above the background */
    height: 100%;
    overflow-y: auto; /* Enable vertical scrolling */
    overflow-x: hidden; /* Prevent horizontal scrolling */

  }
  




  .App {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    /*background: linear-gradient(to right, #d4fc79, #96e6a1); /* Same gradient as body */
  }
  
  .container {
    max-width: 100vw; /* Ensure container does not exceed viewport width */
    overflow: hidden;
  }
  
  /* Add other styles as needed */
  




  @keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(25px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


.animate-on-scroll.start-animation {
  animation: fadeInUp 1.2s ease-in-out forwards;
}

.parent-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Adjust the number of columns as needed */
}

.col-span-2 {
  grid-column: span 2;
}

.App {
  background-color: white !important;
}


.bg-gradient-custom {
  background: linear-gradient(to top right, #ffffff 50%, #ffccff 90%, #ff7b00 90%);
}

.custom-border-width {
  border-width: 5px; /* Example for a 3px border width */

}


.transparent-textarea {
  background-color: transparent; /* Make fill invisible */
  color: black; /* Text color */
  width: 100%; /* Full width */
  height: 100px; /* Fixed height */
  padding: 10px; /* Padding inside the text box */
  border: none; /* Make border invisible */
  border-radius: 5px; /* Rounded corners */
  resize: none; /* Disable resizing */
  overflow-y: auto; /* Enable vertical scrolling */

  /* Create a fading effect for text at the top edge */
  mask-image: linear-gradient(to bottom, transparent, black 10%, black 90%, transparent);
}


.background-div {
  position: fixed;
  top: -30%;
  left: -30%;
  bottom: -30%;
  right: -10%;
  width: 170%;
  height: 180%;
  
  z-index: -1; /* Ensures the background is behind other content */
  background-color: rgb(143, 68, 68); /* Set the base background color */
  background: radial-gradient(circle at 20% -25%, rgba(255, 0, 0, 1) 0%, transparent 30%),

  radial-gradient(circle at 40% -30%, rgb(255, 208, 0,1) 10%, transparent 40%),
  radial-gradient(circle at 40% -30%, rgb(255, 208, 0,1) 10%, transparent 30%),

  radial-gradient(circle at 60% -30%, rgb(83, 248, 0) 0%, transparent 40%),
  radial-gradient(circle at 110% -20%, rgb(21, 0, 255,1) 0%, transparent 45%),

  radial-gradient(circle at 70% -20%, rgb(0, 225, 255,1) 0%, transparent 35%),
  radial-gradient(circle at 70% -20%, rgb(0, 225, 255,1) 0%, transparent 35%),

  radial-gradient(circle at 70% -20%, rgb(0, 225, 255,1) 0%, transparent 35%),

  radial-gradient(circle at 70% -20%, rgb(0, 225, 255,1) 10%, transparent 35%),

  radial-gradient(circle at 10% 10%, rgba(0, 0, 0, 1) 0%, transparent 100%),
  radial-gradient(circle at 10% 10%, rgba(0, 0, 0, 1) 0%, transparent 150%),
   radial-gradient(circle at 50% 100%, rgba(0, 0, 0, 1) 0%, transparent 220%);

  filter: blur(90px); /* Increase the blur radius */
}